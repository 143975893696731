import React from "react";
import { Route, Routes } from "react-router-dom";
import { LinkMoneyFrameComponent } from "./LinkMoneyFrameComponent";
import CancelComponent from "./CancelComponent";

const App: React.FC = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const amount = params.get('amount');
  const firstname = params.get('firstname') || null;
  const lastname = params.get('lastname') || null;
  const mailId = params.get('mailId');
  const feeString = params.get('convenienceFee');
  const fee = feeString ? parseFloat(feeString) : 0;
  const description = params.get('description');
  const toAccount = params.get('toAccount');


  return (
    <Routes>
      <Route
        path="/"
        element={
          <LinkMoneyFrameComponent
            amount={amount}
            firstname={firstname}
            lastname={lastname}
            mailId={mailId}
            convenienceFee={fee}
            loanDescription={description}
            toAccount={toAccount}
          />
        }
      />
      <Route path="/cancel" element={<CancelComponent />} />
    </Routes>
  );
};

export default App;
