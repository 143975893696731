import React, { useEffect, useState, useRef } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from "react-router-dom";


interface LinkMoneyFrameProps {
  amount: string | null;
  firstname: string | null;
  lastname: string | null;
  mailId: string | null;
  convenienceFee: number | null;
  loanDescription: string | null;
  toAccount: string | null;

}

const LinkMoneyFrameComponent: React.FC<LinkMoneyFrameProps> = ({
  amount,
  firstname,
  lastname,
  mailId,
  convenienceFee,
  loanDescription,
  toAccount
}) => {
  const [TokenAuth, setTokenAuth] = useState<string>("");
  const [transactionBackupId, setTransactionBackupId] = useState<number | null>(null);
  const transactionBackupIdRef = useRef<number | null>(null);
  const [transactionDetails, setTransactionDetails] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);
  const TransactionBackupAPI = process.env.REACT_APP_TRANSACTION_BACKUP_API_URL || '';
  const AuthorizeGetToken = process.env.REACT_APP_AUTHORIZE_GET_TOKEN || '';

  const PaymentURL = process.env.REACT_APP_AUTHORIZE_PAYMENT_URL || "";
  const navigate = useNavigate();

  useEffect(() => {

    if (amount !== null) {
      postTransactionBackUp();
    }
  }, [amount, firstname, lastname]);

  useEffect(() => {
    if (transactionBackupId !== null) {
      handleAuthGetToken();
    }
  }, [transactionBackupId]);

  const postTransactionBackUp = async () => {
    const TransactionBackups = {
      transactionBackupId: 0,
      accountHolder: `${firstname} ${lastname}`,
      toAccountNumber: toAccount,
      transactionAmount: parseFloat(amount?.toString() || "0"),
      transactionStatus: "pending",
      isCard: 1,
      mailId: mailId,
      status: 'I',
      convenienceFee: convenienceFee,
      loanDescription: loanDescription
    };

    try {
      const response = await fetch(TransactionBackupAPI, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(TransactionBackups),
      });

      if (!response.ok) {
        throw new Error("Failed to post transaction backup");
      }

      const result = await response.json();
      setTransactionBackupId(result.transactionBackupId);
      transactionBackupIdRef.current = result.transactionBackupId;
    } catch (error) {
      console.error('Error posting transaction:', error);
    }
  };

  const handleAuthGetToken = async () => {
    try {
      const response = await fetch(AuthorizeGetToken, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          request: { amount, email: mailId },
          BillTo: { firstName: firstname, lastName: lastname }
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch token");
      }
      const data = await response.json();
      setTokenAuth(data.token);
      if (data.token) {
        setTimeout(handleOpenPaymentIframe, 1000);
      }
    } catch (error) {
      console.error("Error fetching token:", error);
    }
  };

  const handleOpenPaymentIframe = () => {
    const formPayment = document.getElementById("add_payment") as HTMLFormElement;
    const formToken = document.getElementById("send_token") as HTMLFormElement;
    if (formPayment && formToken) {
      formPayment.style.display = "block";
      formToken.action = PaymentURL;
      formToken.target = "add_payment";
      formToken.submit();

      window.scrollTo({
        top: formPayment.offsetTop - 50,
        behavior: "smooth",
      });
    }
  };

  const handleReceiveCommunication = async (querystr: string) => {

    const params = parseQueryString(querystr);
    console.log(params, '---->>>');
    if (params["action"] === 'transactResponse') {
      const responseObject = JSON.parse(params["response"]);
      console.log(responseObject, '----->>')
      const Status = responseObject.responseCode == 1 ? 'success' : 'pending';
      const transStatus = Status == "success" ? 'C' : 'I'
      const TransactionBackups = {
        transactionBackupId: transactionBackupIdRef.current,
        TransactionNumber: responseObject.transId,
        accountHolder: `${responseObject.billTo.firstName} ${responseObject.billTo.lastName}`,
        transactionAmount: responseObject.totalAmount,
        FromAccountNumber: responseObject.accountNumber,
        accountName: responseObject.accountType,
        scheduledDate: new Date(responseObject.dateTime).toISOString(),
        isCard: 1,
        mailId: mailId,
        status: transStatus,
        convenienceFee: convenienceFee,
        transactionStatus: Status,
        loanDescription: loanDescription
      };

      try {
        const responses = await fetch(TransactionBackupAPI, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(TransactionBackups),
        });
        const results = await responses.json();
        setTransactionDetails(TransactionBackups);

        setShowModal(true);
      } catch (error) {
        console.error('Error posting transaction:', error);
      }
      const addPaymentIframeResponse = document.getElementById('add_payment') as HTMLIFrameElement;
      if (addPaymentIframeResponse) {
        addPaymentIframeResponse.style.display = 'none';
        console.log(responseObject);
      }
    }
    else if (params["action"] === 'cancel') {
      navigate("/cancel");
    }
  };

  const parseQueryString = (str: string) => {
    const vars: { [key: string]: string } = {};
    const arr = str.split("&");
    arr.forEach((item) => {
      const [key, value] = item.split("=");
      vars[key] = decodeURIComponent(value);
    });
    return vars;
  };

  useEffect(() => {
    if (!(window as any).AuthorizeNetIFrame) {
      (window as any).AuthorizeNetIFrame = {};
      (window as any).AuthorizeNetIFrame.onReceiveCommunication = handleReceiveCommunication;
    }
  }, []);

  const handleCloseModal = () => {
    const transactionNumber = transactionDetails?.TransactionNumber ?? "no transaction";
    window.parent.postMessage({ transactionNumber }, '*');
    setShowModal(false);
  };

  return (
    <div>
      <div className="pagetitle-main">
        <div className="container">
          <div id="iframe_holder" className="center-block" style={{ width: "100%" }} >
            <iframe
              id="add_payment"
              className="embed-responsive-item panel"
              name="add_payment"
              width="100%"
              style={{ display: "none" }}
              height="350px"
            ></iframe>
          </div>
        </div>
      </div>

      <form id="send_token" action="" method="post" target="add_payment">
        <input type="hidden" name="token" value={TokenAuth} />
      </form>
      <div
        className="modal show"
        style={{ display: 'block', position: 'initial' }}
      >
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Transaction {transactionDetails?.status === 'C' ? 'Successful' : 'Status'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {transactionDetails ? (
              <>
                <p>Your transaction was {transactionDetails.status === 'C' ? 'completed successfully' : 'not completed.'}!</p>
                <p>Transaction Number: {transactionDetails.TransactionNumber}</p>
                <p>Amount: {transactionDetails.transactionAmount}</p>
                <p>Status: {transactionDetails.status === 'C' ? 'Successful' : 'Incomplete'}</p>
              </>
            ) : (
              <p>Transaction details are not available at this moment.</p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" className="btn btn-primary" onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

      </div>
    </div>
  );
};

export { LinkMoneyFrameComponent };
