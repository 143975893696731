import React from "react";
import { useNavigate } from "react-router-dom";

const CancelComponent: React.FC = () => {
    const navigate = useNavigate();
    const transactionNumber = 0;

    const handleGoBack = () => {
        console.log("handleGoBack function called");
        console.log("Transaction Number:", transactionNumber);

        // Attempt to post message and log success or failure
        try {
            window.parent.postMessage({ transactionNumber }, '*');
            console.log("Message posted successfully");
        } catch (error) {
            console.error("Error posting message:", error);
        }
    };

    return (
        <div className="cancel-component text-center">
            <h1>Payment cancelled</h1>
            <p>Your payment process was cancelled. If this was a mistake you can try again.</p>
            <button className="btn btn-primary" onClick={handleGoBack}>Go Back</button>
        </div>
    );
};

export default CancelComponent;
